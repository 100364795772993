@import 'src/utils/utils';

.chips {
  display: flex;
  margin-right: -16px;
  margin-left: -16px;
  padding-right: 16px;
  padding-left: 16px;
  overflow-x: auto;
  white-space: nowrap;
  gap: 6px;

  @media(min-width: $md) {
    margin-right: -16px;
    margin-left: -16px;
  }
  @media(min-width: $lg) {
    margin-right: unset;
    margin-left: unset;
    padding-right: unset;
    padding-left: unset;
    white-space: wrap;
    flex-wrap: wrap;
    overflow: unset;
  }

  &__item {
    @include Text-14-reg;
    padding-top: 6px;
    padding-right: 10px;
    padding-bottom: 7px;
    padding-left: 10px;
    color: $Main-1;
    border: 0;
    border-radius: 20px;
    background-color: $Main-2;
    text-decoration: none;
    transition:
      color 0.3s,
      opacity 0.3s;
    cursor: pointer;

    &:not(.chips__item--active):hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    &:not(.chips__item--active):active {
      opacity: 0.7;
    }

    &--active {
      color: $Main-2;
      background-color: $Corporate-2;
    }
  }
}